import { Fragment } from "react";
import { CircledCheckmark } from "../shared/icon/icons";

import "./OnboardingStep.css";

const OnboardingStep = ({
  step,
  headline,
  description,
  success,
}: {
  step: string;
  headline: string;
  description: string;
  success?: boolean;
}) => {
  return (
    <Fragment>
      <div className="flex justify-content-center align-items-center onboarding-step">
        {success ? <CircledCheckmark width={24} height={24} /> : step}
      </div>
      <div className="flex column-nowrap onboarding-step-header">
        <div className="onboarding-step-headline">{headline}</div>
        <div className="onboarding-step-description">{description}</div>
      </div>
    </Fragment>
  );
};

export default OnboardingStep;
