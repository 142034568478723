import { Dispatch, Fragment, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router";
import "react-tabs/style/react-tabs.css";

import DashboardNavigation from "../shared/components/account/DashboardNavigation";
import PageWrapper from "../shared/components/page/PageWrapper";
import ContentWrapper from "../shared/components/page/ContentWrapper";

import "./Markies.css";
import { Camera } from "../shared/icon/icons";
import Banner from "../shared/components/Banner";
import Loader from "../shared/components/loader/Loader";
import { useQueryString } from "../shared/utils";
import MarkieCards from "./MarkieCards";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { Markie } from "./AddMarkie";
import { fetchMarkies } from "../shared/fetch";
import { updateMarkie } from "../slice";
import { AnyAction } from "@reduxjs/toolkit";

export const updateMarkies = (dispatch: Dispatch<AnyAction>) => {
  fetchMarkies().then((markies) => {
    const sortMarkies = markies.slice();
    sortMarkies.sort((a: Markie, b: Markie) => b.createdAt - a.createdAt);

    dispatch(updateMarkie(sortMarkies));
  });
};

const Markies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const markies: Markie[] | null = useSelector(
    (state: RootState) => state.dashboard.markies
  );
  const query = useQueryString();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleCurrentTab = (index: number) => {
    navigate(`/markies?tab=${index}`);
    setCurrentTab(index);
  };

  useEffect(() => {
    const tab = query.get("tab");
    if (tab) setCurrentTab(parseInt(tab));
  }, []);

  useEffect(() => {
    updateMarkies(dispatch);
  }, []);

  return (
    <PageWrapper bgColour="light-blue">
      <DashboardNavigation />
      <ContentWrapper headline="Markies">
        <Tabs
          selectedIndex={currentTab}
          onSelect={(index) => handleCurrentTab(index)}
          forceRenderTabPanel
        >
          <TabList className="flow-tab-list">
            <Tab
              className="flow-tab-list-item"
              selectedClassName="flow-tab-list-item--active"
            >
              All
            </Tab>
          </TabList>
          <TabPanel>
            <Banner
              icon={<Camera fill="#146aff" />}
              message="Onboard customers in minutes"
              link="/markie/new"
              actionLabel="New Markie"
              version="primary"
              buttonVersion="primary"
            />
            <div className="flex spacer spacer--md" />

            {markies && markies.length !== 0 && (
              <MarkieCards markies={markies} />
            )}

            {markies && markies.length === 0 && (
              <Fragment>
                <div className="flex spacer"></div>
                <p className="flex markies-cards-description">No Markies</p>
              </Fragment>
            )}
          </TabPanel>
        </Tabs>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Markies;
