import Login from "../../../account/Login";
import "./PageWrapper.css";
import { Fragment } from "react";

const PageWrapper = ({
  bgColour,
  noScroll = false,
  children,
}: {
  bgColour?: string;
  noScroll?: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  const profile = true;

  return (
    <Fragment>
      <Fragment>
        {profile ? (
          <div
            className={`page-wrapper page-wrapper--${bgColour} page-wrapper--${
              noScroll ? "noscroll" : "scroll"
            }`}
          >
            <div className="page-wrapper-children">{children}</div>
          </div>
        ) : (
          <Login />
        )}
      </Fragment>
    </Fragment>
  );
};

export default PageWrapper;
