import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Markie } from "./account/AddMarkie";
import { AppearanceSettings } from "./account/Settings";

export interface StoreState {
  id: string | null;
  userName: string | null;
  appearance: AppearanceSettings | null;
  markies: Markie[] | null;
}

const initialState: StoreState = {
  id: null,
  userName: null,
  appearance: null,
  markies: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    updateId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    updateAppearance: (state, action: PayloadAction<AppearanceSettings>) => {
      state.appearance = action.payload;
    },
    updateMarkie: (state, action: PayloadAction<Markie[]>) => {
      state.markies = action.payload;
    },
    updateUser: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { reset, updateAppearance, updateMarkie, updateUser, updateId } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
