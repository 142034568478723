import "./Button.css";

const ActionButton = ({
  icon,
  label,
  className,
  onClick,
  type,
  version,
  stretch = false,
  disabled = false,
  active = false,
  extra,
  onMouseOver,
  onMouseLeave,
  submit,
}: {
  icon?: JSX.Element;
  label?: string;
  className?: string;
  onClick: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  type: "xs" | "sm" | "md";
  version:
    | "primary"
    | "secondary"
    | "error"
    | "error-plain"
    | "plain"
    | "rounded"
    | "success";
  stretch?: boolean;
  disabled?: boolean;
  active?: boolean;
  extra?: JSX.Element;
  submit?: boolean;
}) => {
  const handleOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  const handleMouseOver = () => {
    if (onMouseOver) onMouseOver();
  };

  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave();
  };

  return (
    <button
      className={`flex row-nowrap align-items-center web-button web-button--${version}-${
        active ? "active" : "inactive"
      } web-button--${version} web-button--${type} ${
        !label ? "web-button--icon" : ""
      } ${stretch ? "web-button--stretch" : ""} ${className ? className : ""}`}
      onClick={handleOnClick}
      disabled={disabled}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      type={submit ? "submit" : "button"}
    >
      {icon && (
        <div className="flex justify-content-center align-items-center icon">
          {icon}
        </div>
      )}
      {label && (
        <p className={`text ${icon ? "text-with-icon" : ""}`}>{label}</p>
      )}
      {extra && <div className="web-button--extra">{extra}</div>}
    </button>
  );
};

export default ActionButton;
