import { useLocation } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_URL;
const CLIENT_URL = process.env.REACT_APP_APP_URL;
export const COMMAND_BAR_ID = process.env.REACT_APP_COMMAND_BAR_ID;

export const clientUrl = (path: string) => {
  if (path.startsWith("/")) return CLIENT_URL + path;
  else return CLIENT_URL + "/" + path;
};

export const apiUrl = (path: string) => {
  if (path.startsWith("/")) return BASE_URL + path;
  else return BASE_URL + "/" + path;
};

export const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};
export const renderAppName = (name: string) => {
  const fileName = name.toLowerCase().split(" ").join("-");
  return fileName;
};

interface HasAppType {
  app: string;
}

export function groupByApp<T extends HasAppType>(elements: T[]) {
  const grouped = elements.reduce((acc: Record<string, T[]>, element: T) => {
    const app: T[] = acc[element.app] || [];
    app.push(element);
    acc[element.app] = app;
    return acc;
  }, {});
  return Object.keys(grouped).map((app) => ({
    app,
    elements: grouped[app],
  }));
}
