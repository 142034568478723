import "./Logo.css";

const Logo = () => {
  return (
    <div className="flex markie-logo">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="84"
        height="21"
        viewBox="0 0 84 21"
      >
        <g>
          <g id="logomark_1_">
            <path
              id="m-canvas_1_"
              className="markie-logo-m-canvas"
              d="M2.1,0h17.3c1.2,0,2.1,0.9,2.1,2.1v16.8c0,1.2-0.9,2.1-2.1,2.1H2.1C0.9,21,0,20.1,0,18.9
			V2.1C0,0.9,0.9,0,2.1,0z"
            />
            <path
              id="m_1_"
              className="markie-logo-m"
              d="M5.2,6.8h1.6l0.3,1.1c0.2-0.4,0.5-0.8,0.8-1c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,1,0.1,1.4,0.4
			s0.7,0.6,0.9,1.1c0.2-0.5,0.5-0.8,0.9-1.1c0.4-0.3,0.9-0.4,1.4-0.4c0.5,0,0.9,0.1,1.3,0.3s0.7,0.6,0.9,1c0.2,0.4,0.3,0.9,0.3,1.5
			v4.4h-2v-4c0-0.4-0.1-0.8-0.4-1c-0.2-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4C12,9,11.8,9.4,11.8,9.8v4h-2v-4
			c0-0.4-0.1-0.8-0.3-1.1C9.2,8.5,8.9,8.3,8.6,8.3c-0.4,0-0.7,0.1-0.9,0.4S7.3,9.4,7.3,9.8v4h-2V6.8z"
            />
          </g>
          <path
            id="type_1_"
            className="markie-logo-type"
            d="M81.3,11.1h-3.9c0.1-0.6,0.3-1,0.7-1.4c0.4-0.3,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5
		C81,10.1,81.2,10.5,81.3,11.1z M79.8,16.4c0.9,0,1.8-0.2,2.6-0.5c0.8-0.3,1.3-0.8,1.6-1.4l-1.5-1.1c-0.2,0.3-0.5,0.6-1,0.8
		c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.4-0.8-0.8-0.9-1.4h6.4c0-0.1,0-0.4,0-0.7c0-0.9-0.2-1.7-0.5-2.4
		c-0.4-0.7-0.9-1.2-1.5-1.6c-0.7-0.4-1.4-0.6-2.3-0.6S77.7,7.5,77,7.9s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.5-0.6,2.3
		c0,0.9,0.2,1.7,0.7,2.4c0.4,0.7,1,1.2,1.8,1.6C78,16.2,78.9,16.4,79.8,16.4z M73.6,16.1V7.6h-2.5v8.5H73.6z M72.4,6.5
		c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4
		c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.8,0.4,1.1C71.6,6.3,72,6.5,72.4,6.5z M64.4,16.1v-2.6l1-1l2.2,3.7h2.8L67,10.9l3.2-3.3h-3
		l-2.8,3.1V3.8h-2.5v12.4H64.4z M55.8,16.1v-4.7c0-0.6,0.1-1.2,0.4-1.6c0.3-0.4,0.7-0.6,1.1-0.6c0.4,0,0.7,0.2,1,0.4
		c0.3,0.3,0.4,0.7,0.5,1.1l2.1-0.6c-0.1-0.9-0.4-1.6-0.9-2.1c-0.5-0.5-1.1-0.8-1.9-0.8c-0.6,0-1.1,0.1-1.5,0.4
		c-0.4,0.3-0.8,0.7-1,1.2l-0.4-1.4h-2v8.5H55.8z M46.4,14.6c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.4-0.4-0.4-0.7c0-0.3,0.1-0.5,0.4-0.7
		s0.8-0.3,1.4-0.4l1.8-0.2v0.4c0,0.6-0.2,1-0.6,1.4C47.5,14.4,47,14.6,46.4,14.6z M50.4,16.4c0.4,0,0.8-0.1,1.2-0.2
		c0.4-0.1,0.7-0.3,1-0.6l-0.7-1.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.4v-3.4c0-0.6-0.2-1.2-0.5-1.7C50.1,8.4,49.6,8,49,7.8c-0.6-0.3-1.3-0.4-2.1-0.4c-0.7,0-1.4,0.1-2.1,0.4
		c-0.6,0.2-1.1,0.6-1.5,1c-0.4,0.4-0.6,0.9-0.7,1.3l2.3,0.6c0-0.4,0.3-0.7,0.6-1c0.4-0.3,0.8-0.4,1.3-0.4c0.4,0,0.8,0.1,1.1,0.3
		c0.3,0.2,0.5,0.5,0.5,0.7c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2l-1.7,0.2c-1.2,0.1-2.1,0.4-2.8,0.9c-0.6,0.5-1,1.1-1,1.9
		c0,0.5,0.1,0.9,0.4,1.3c0.3,0.4,0.6,0.7,1.1,0.9c0.5,0.2,1,0.3,1.6,0.3c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.2-1.2l0,0.1
		c0,0.4,0.3,0.8,0.6,1C49.5,16.3,49.9,16.4,50.4,16.4z M29.5,16.1V7.9l3.4,8.3h2.3l3.4-8.3v8.3h2.6V4.3h-3.7L34,13l-3.5-8.7h-3.7
		v11.9H29.5z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
