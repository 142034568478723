import { Link } from "react-router-dom";
import "./AccountMenuItem.css";

const AccountMenuItem = ({
  icon,
  to,
  label,
  onClick,
}: {
  icon: JSX.Element;
  to?: string;
  label: string;
  onClick?: () => void;
}) => {
  if (onClick) {
    return (
      <button
        className="flex align-items-center account-menu-item"
        onClick={onClick}
      >
        {icon} <span className="account-menu-item-label">{label}</span>
      </button>
    );
  } else if (to) {
    return (
      <Link to={to} className="flex align-items-center account-menu-item">
        {icon} <span className="account-menu-item-label">{label}</span>
      </Link>
    );
  } else {
    return null;
  }
};

export default AccountMenuItem;
