import { useNavigate } from "react-router";
import "./Button.css";

const InternalButton = ({
  icon,
  label,
  className,
  to,
  type,
  version,
  stretch = false,
  active = false,
  onMouseOver,
  onMouseLeave,
  disabled,
}: {
  icon?: JSX.Element;
  label?: string;
  className?: string;
  target?: "blank" | "self";
  to: string;
  type: "xs" | "sm" | "md";
  version: "primary" | "secondary" | "error" | "plain" | "rounded";
  stretch?: boolean;
  active?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();

  const handleMouseOver = () => {
    if (onMouseOver) onMouseOver();
  };

  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave();
  };

  return (
    <button
      onClick={() => navigate(to)}
      className={`flex row-nowrap align-items-center web-button web-button--${version}-${
        active ? "active" : "inactive"
      } web-button--${version} web-button--${type} ${
        !label ? "web-button--icon" : ""
      } ${stretch ? "web-button--stretch" : ""} ${className ? className : ""}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
    >
      {icon && (
        <div className="flex justify-content-center align-items-center icon">
          {icon}
        </div>
      )}
      {label && (
        <p className={`text ${icon ? "text-with-icon" : ""}`}>{label}</p>
      )}
    </button>
  );
};

export default InternalButton;
