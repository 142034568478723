import axios from "axios";
import { apiUrl } from "./utils";

export const fetchUsername = async () => {
  const username = await axios
    .get(apiUrl("account/username"), { withCredentials: true })
    .then(({ data }) => data)
    .catch((e) => console.error(e));
  return username;
};

export const fetchAppearance = async () => {
  const appearance = await axios
    .get(apiUrl("account/fetch/appearance"), { withCredentials: true })
    .then(({ data }) => data)
    .catch((e) => console.error(e));
  return appearance;
};

export const fetchMarkies = async () => {
  const markies = await axios
    .get(apiUrl("markie/all"), { withCredentials: true })
    .then(({ data }) => data)
    .catch((e) => console.error(e));
  return markies;
};

export const fetchId = async () => {
  const id = await axios
    .get(apiUrl("account/id"), { withCredentials: true })
    .then(({ data }) => data)
    .catch((e) => console.error(e));
  return id;
};
