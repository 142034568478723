import axios from "axios";
import jwtDecode from "jwt-decode";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ExternalButton from "../shared/components/button/ExternalButton";
import Chip from "../shared/components/Chip";

import Logo from "../shared/components/logo/Logo";

import { apiUrl } from "../shared/utils";
import { useQueryString } from "../shared/utils";

import "./Register.css";

const RegisterThirdStep = ({
  password,
  code,
}: {
  password?: string;
  code?: string;
}) => {
  const navigate = useNavigate();
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);
  const [infoSubmitted, setInfoSubmitted] = useState<boolean>(false);
  const [existingAccount, setExistingAccount] = useState<string | null>(null);

  const submitInfo = async (
    firstName: string,
    lastName: string,
    company: string
  ) => {
    setInfoSubmitted(true);
    const loadingId = toast.loading("Creating account...");
    axios
      .post(
        apiUrl("auth/create"),
        {
          user: {
            firstName,
            lastName,
            password,
          },
          account: {
            name: company,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${code}`,
          },
          withCredentials: true,
        }
      )
      .then((_) => {
        navigate("/overview");
        toast.success("Account created successfully", { id: loadingId });
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error(`E-mail already has an account. Please login instead.`, {
            id: loadingId,
          });
        } else if (e.response.status === 401) {
          toast.error(
            `Your registration link is expired. Please request another one.`,
            {
              id: loadingId,
            }
          );
        } else {
          toast.error(
            `Could not create account. Please contact support and share this message: ${e}`,
            { id: loadingId }
          );
        }
      });
  };

  return (
    <Fragment>
      <header className="flex column-nowrap align-items-center account-form-header">
        <h1 className="account-form-headline">Get started</h1>
        <p className="account-form-description">
          A few steps away from onboarding customers
        </p>
      </header>
      <form
        className="flex column-nowrap account-form"
        onSubmit={async (e) => {
          e.preventDefault();
          if (
            firstNameRef.current &&
            lastNameRef.current &&
            companyRef.current
          ) {
            submitInfo(
              firstNameRef.current.value,
              lastNameRef.current.value,
              companyRef.current.value
            );
          }
        }}
      >
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="firstname" className="input-label">
            First name
          </label>
          <input
            id="firstname"
            type="text"
            className="input"
            autoFocus
            ref={firstNameRef}
            placeholder="Your firstname"
          ></input>
        </div>
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="lastname" className="input-label">
            Last name
          </label>
          <input
            id="lastname"
            type="text"
            className="input"
            ref={lastNameRef}
            placeholder="Your lastname"
          ></input>
        </div>
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="company" className="input-label">
            Company name
          </label>
          <input
            id="company"
            type="text"
            className="input"
            ref={companyRef}
            placeholder={existingAccount || "ACME Inc."}
            disabled={existingAccount !== null}
          ></input>
        </div>
        <div className="flex spacer"></div>
        <button
          type="submit"
          className="web-button web-button--primary web-button--md web-button--stretch"
          disabled={infoSubmitted}
        >
          Submit
          <div className="web-button--extra">
            <Chip
              text={[
                {
                  id: "2mod",
                  name: "enter",
                },
              ]}
              version="light"
            />
          </div>
        </button>
      </form>
    </Fragment>
  );
};

const RegisterSecondStep = ({
  email,
  finishedSetter,
}: {
  email: string;
  finishedSetter: (password: string) => void;
}) => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const setPassword = (password: string) => {
    finishedSetter(password);
  };

  return (
    <Fragment>
      <header className="flex justify-content-center account-form-header">
        <h1 className="account-form-headline">Select a password</h1>
      </header>
      <form
        className="flex column-nowrap account-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (passwordRef.current) setPassword(passwordRef.current.value);
          setSubmitted(true);
        }}
      >
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="email" className="input-label">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="input"
            placeholder="Your email"
            value={email}
            disabled
          ></input>
        </div>
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="password" className="input-label">
            Password
          </label>
          <input
            id="password"
            type="password"
            className="input"
            placeholder="Your password"
            ref={passwordRef}
            disabled={submitted}
          ></input>
        </div>
        <div className="flex spacer"></div>
        <button
          type="submit"
          className="web-button web-button--primary web-button--md web-button--stretch"
          disabled={submitted}
        >
          Submit
          <div className="web-button--extra">
            <Chip
              text={[
                {
                  id: "2mod",
                  name: "enter",
                },
              ]}
              version="light"
            />
          </div>
        </button>
      </form>
    </Fragment>
  );
};

const RegisterFirstStep = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const invitationCodeRef = useRef<HTMLInputElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [successSubmit, _setSuccessSubmit] = useState<boolean>(false);
  const [stepFailed, setStepFailed] = useState<string | null>(null);

  const submitEmail = async (email: string, invitationCode?: string) => {
    setStepFailed(null);
    try {
      const registrationUrl = await axios
        .post(apiUrl("auth/email-signup/"), { email, invitationCode })
        .then((response) => {
          return response.data;
        });
      window.location.href = registrationUrl;
    } catch (error: any) {
      // we should give a better error message here but for now we just show a generic one (email already exists for example)
      setStepFailed("Failed to submit email");
      setSubmitted(false);
    }
  };

  return (
    <Fragment>
      <header className="flex justify-content-center account-form-header">
        <h1 className="account-form-headline">Create an account</h1>
      </header>
      <form
        className="flex column-nowrap account-form"
        onSubmit={(e) => {
          setSubmitted(true);
          e.preventDefault();
          if (emailRef.current) {
            submitEmail(
              emailRef.current.value,
              invitationCodeRef.current?.value
            );
          }
        }}
      >
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="email" className="input-label">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="input"
            autoFocus
            ref={emailRef}
            placeholder="Your email"
            disabled={submitted}
          ></input>
        </div>
        {successSubmit && (
          <div className="flex justify-content-center attention-wrapper">
            <p className="flex sign-up-info">
              <span className="flex signup-info-text">
                Thank you for registering. <br /> Follow the link sent to your
                email.
              </span>
            </p>
          </div>
        )}
        <div className="flex spacer"></div>
        <button
          type="submit"
          className="web-button web-button--primary web-button--md web-button--stretch"
          disabled={submitted}
        >
          Submit
          <div className="web-button--extra">
            <Chip
              text={[
                {
                  id: "2mod",
                  name: "enter",
                },
              ]}
              version="light"
            />
          </div>
        </button>
      </form>
      <div className={`flex column-nowrap align-items-center divider`}>
        <p className="divider-text">or</p>
        <div className="line"></div>
      </div>
      <ExternalButton
        version="secondary"
        type="md"
        to={apiUrl("auth/google")}
        label="Sign up with Google"
        target="self"
        stretch
      />
      <div className="flex spacer"></div>
      <p className="flex justify-content-center sign-up-info">
        <span className="signup-info-text">Back to login?</span>
        <Link to="/login" className="signup-info-text signup-info-text--link">
          Click here
        </Link>
      </p>
      {stepFailed && (
        <div className="flex attention-wrapper">
          <p className="flex justify-content-center sign-up-info">
            <span className="signup-info-text">
              Sign up failed with error: {stepFailed}
            </span>
          </p>
        </div>
      )}
    </Fragment>
  );
};

const Register = () => {
  const [step, setStep] = useState<number>(0);
  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [password, setPassword] = useState<string | undefined>();

  const query = useQueryString();

  const redirectUrl = query.get("redirect_url");

  useEffect(() => {
    if (redirectUrl) {
      // Cookies.set(openflowLoginRedirectCookie, redirectUrl);
    }
  }, [redirectUrl]);

  useEffect(() => {
    const code = query.get("code");
    const jumpStep = query.get("step");
    if (code) {
      const { email }: { email: string } = jwtDecode(code);
      setEmail(email);
      setCode(code);
      if (jumpStep) setStep(parseInt(jumpStep));
      else setStep(2);
    } else {
      setStep(1);
    }
  }, []);

  return (
    <div className="flex login-wrapper">
      <div className="flex column-nowrap align-items-center login-container">
        <div className="flex justify-content-center login-logo-wrapper">
          <Logo />
        </div>
        <div className="flex column-nowrap account-form-wrapper">
          {step === 1 && <RegisterFirstStep />}
          {step === 2 && email && code && (
            <RegisterSecondStep
              finishedSetter={(password: string) => {
                setPassword(password);
                setStep(3);
              }}
              email={email}
            />
          )}
          {step === 3 && email && code && (
            <RegisterThirdStep code={code} password={password} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
