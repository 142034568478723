import { Route, Routes, useNavigate } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Overview from "./account/Overview";
import Markies from "./account/Markies";
import AddMarkie from "./account/AddMarkie";
import Settings from "./account/Settings";
import Register from "./account/Register";
import Login from "./account/Login";
import "./App.css";
import { Fragment, useEffect } from "react";
import axios from "axios";
import { Invitations } from "./account/Invitations";
import Analytics from "./account/Analytics";

const SetupAxiosInterceptor = () => {
  const navigate = useNavigate();
  useEffect(() => {
    axios.interceptors.response.use(
      (success) => success,
      (error) => {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
        throw error;
      }
    );
  }, []);
  return <></>;
};

const Container = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <Fragment>{children}</Fragment>;
};

const App = () => {
  useEffect(() => {
    /* @ts-ignore:disable-next-line */
    $crisp.push(["do", "chat:hide"]);

    /* @ts-ignore:disable-next-line */
    $crisp.push([
      "on",
      "chat:closed",
      () => {
        /* @ts-ignore:disable-next-line */
        $crisp.push(["do", "chat:hide"]);
      },
    ]);
  }, []);

  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "markie-toast",
        }}
      />
      <SetupAxiosInterceptor />
      <Container>
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/markies" element={<Markies />} />
          <Route path="/markie/:markieId" element={<AddMarkie />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/invitations" element={<Invitations />} />
          <Route path="/analytics" element={<Analytics />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
};

export default App;
