import "./ContentWrapper.css";

const ContentWrapper = ({
  headline,
  children,
}: {
  headline?: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <section
      className={`flex column-nowrap content-wrapper content-wrapper--full`}
    >
      <header className="content-wrapper-header">
        <h1 className="content-wrapper-headline">{headline}</h1>
      </header>
      <div className="content-wrapper-children">{children}</div>
    </section>
  );
};

export default ContentWrapper;
