import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardNavigation from "../shared/components/account/DashboardNavigation";

import InternalButton from "../shared/components/button/InternalButton";
import ContentWrapper from "../shared/components/page/ContentWrapper";
import PageWrapper from "../shared/components/page/PageWrapper";

import { RootState } from "../store";
import { Markie } from "./AddMarkie";
import { updateMarkies } from "./Markies";
import "./Analytics.css";
import { Link } from "react-router-dom";

const Analytics = () => {
  const dispatch = useDispatch();
  const markies: Markie[] | null = useSelector(
    (state: RootState) => state.dashboard.markies
  );

  useEffect(() => {
    updateMarkies(dispatch);
  }, []);

  return (
    <PageWrapper bgColour="light-blue">
      <DashboardNavigation />
      <ContentWrapper headline="Analytics">
        <div className="flex column-nowrap analytics-table">
          <div className="flex row-nowrap justify-content-space-between analytics-table-header">
            <div className="flex analytics-table-title analytics-table-headline">
              title
            </div>
            <div className="flex analytics-table-headline">Impressions</div>
            <div className="flex analytics-table-headline">(un)muted</div>
            <div className="flex analytics-table-headline">fullscreen</div>
            <div className="flex analytics-table-headline">hidden</div>
            <div className="flex analytics-table-headline"></div>
          </div>
          <div className="flex column-nowrap justify-content-space-between analytics-table-body">
            {markies?.map((markie) => (
              <Link
                to={`/markie/${markie._id}`}
                className="flex row-nowrap justify-content-space-between align-items-center analytics-table-row"
              >
                {markie.analytics && (
                  <Fragment>
                    <div className="flex analytics-table-data analytics-table-data-bold analytics-table-title-row">
                      {markie.title}
                    </div>
                    <div className="flex analytics-table-data">
                      {markie.analytics.displayed}
                    </div>
                    <div className="flex analytics-table-data">
                      {markie.analytics.unmuted}
                    </div>
                    <div className="flex analytics-table-data">
                      {markie.analytics.fullscreen}
                    </div>
                    <div className="flex analytics-table-data">
                      {markie.analytics.hidden}
                    </div>
                    <div className="flex justify-content-flex-end analytics-table-data">
                      <InternalButton
                        type="sm"
                        version="secondary"
                        label="Edit"
                        to={`/markie/${markie._id}`}
                      />
                    </div>
                  </Fragment>
                )}
              </Link>
            ))}
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Analytics;
