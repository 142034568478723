import DashboardNavigation from "../shared/components/account/DashboardNavigation";
import PageWrapper from "../shared/components/page/PageWrapper";
import ContentWrapper from "../shared/components/page/ContentWrapper";
import AccountInfoGroup from "./AccountInfoGroup";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import AccountContentBlock from "./AccountContentBlock";
import "./Settings.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { fetchAppearance } from "../shared/fetch";
import { updateAppearance } from "../slice";
import axios from "axios";
import { apiUrl } from "../shared/utils";
import MarkieVisualizer from "./MarkieVisualizer";
import Loader from "../shared/components/loader/Loader";
import ActionButton from "../shared/components/button/ActionButton";
import { Trash } from "../shared/icon/icons";

export interface AppearanceSettings {
  logo?: string;
  primaryColour: string;
  secondaryColour: string;
  appearance: "round" | "rectangle";
}

const Settings = () => {
  const dispatch = useDispatch();
  const appearance = useSelector(
    (root: RootState) => root.dashboard.appearance
  );
  const [modifiedAppearance, setModifiedAppearance] =
    useState<AppearanceSettings | null>(null);
  const [isLogoUploading, setLogoUploading] = useState<boolean>(false);
  const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const checkAppearance = () => {
    fetchAppearance().then((appearance) => {
      dispatch(updateAppearance(appearance));
      setLogoUploading(false);
    });
  };

  useEffect(() => {
    if (appearance) {
      setModifiedAppearance(appearance);
    }
  }, [appearance]);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsSavingSettings(true);
    await axios
      .post(apiUrl("account/set/appearance"), modifiedAppearance, {
        withCredentials: true,
      })
      .then(() => {
        checkAppearance();
        setIsSavingSettings(false);
        setChangesMade(false);
      });
  };

  const uploadLogo = async (file: ChangeEvent) => {
    setLogoUploading(true);
    const { files } = file.target as HTMLInputElement;
    if (!files || files.length === 0) return;
    const formData = new FormData();
    formData.append("file", files[0]);
    await axios.post(apiUrl("account/upload/logo"), formData, {
      withCredentials: true,
    });
    checkAppearance();
  };

  return (
    <PageWrapper bgColour="light-blue">
      <DashboardNavigation />
      <ContentWrapper headline="Settings">
        <AccountInfoGroup
          headline={"Personal touch"}
          description="Make it looks yours"
        >
          <Fragment>
            {appearance && modifiedAppearance && (
              <form
                className="flex column-nowrap add-markie-wrapper"
                onSubmit={handleFormSubmit}
              >
                <div className="flex row-nowrap account-content-block justify-content-flex-end align-items-center">
                  {isSavingSettings && (
                    <Fragment>
                      <Loader version="dark" text="Saving" />
                      <div className="flex spacer spacer--horizontal spacer--horizontal--md"></div>
                    </Fragment>
                  )}
                  {!isSavingSettings && changesMade && (
                    <div className="markie-changes">changes made</div>
                  )}
                  <button
                    type="submit"
                    className="flex row-nowrap align-items-center web-button web-button--primary web-button--sm"
                  >
                    Save
                  </button>
                </div>
                <AccountContentBlock
                  headline="Logo"
                  labelFor="upload-logo"
                  subheadline="Logo appears on fullscreen videos"
                >
                  <div className="flex column-nowrap account-content-group-wrapper">
                    <label
                      htmlFor="upload-logo"
                      className="flex column-nowrap justify-content-center logo-upload-wrapper"
                    >
                      {modifiedAppearance.logo && (
                        <ActionButton
                          icon={<Trash fill="#fff" />}
                          version="error"
                          type="xs"
                          onClick={() => {
                            setModifiedAppearance({
                              ...modifiedAppearance,
                              logo: "",
                            });
                          }}
                          className="markie-logo-delete"
                        />
                      )}
                      <div className="flex justify-content-center logo-upload-headline">
                        Click{" "}
                        <span className="flex logo-upload-underline">here</span>{" "}
                        to upload
                      </div>
                      <div className="flex justify-content-center logo-upload-subheadline">
                        SVG, PNG, JPG or GIF (max 5MB)
                      </div>
                      <input
                        id="upload-logo"
                        name="upload-logo"
                        type="file"
                        className="upload-input"
                        onChange={(file) => {
                          uploadLogo(file);
                          setChangesMade(true);
                        }}
                      ></input>
                    </label>
                    {modifiedAppearance.logo && (
                      <div className="flex visualizer logo-visualizer">
                        {isLogoUploading ? (
                          <Loader text="Uploading" version="dark" />
                        ) : (
                          <img
                            key={modifiedAppearance.logo}
                            src={modifiedAppearance.logo}
                            alt="logo"
                            className="logo-upload-preview"
                          />
                        )}
                      </div>
                    )}

                    {!modifiedAppearance.logo && isLogoUploading && (
                      <div className="flex visualizer logo-visualizer">
                        <Loader text="Uploading" version="dark" />
                      </div>
                    )}
                  </div>
                </AccountContentBlock>
                <AccountContentBlock headline="Colours">
                  <div className="flex column-nowrap account-content-group-wrapper">
                    <div className="flex column-nowrap builder-step-group builder-step-group--md">
                      <label
                        htmlFor="primary"
                        className="input-label input-sublabel"
                      >
                        Primary
                      </label>
                      <input
                        id="primary"
                        type="primary"
                        className="input"
                        defaultValue={appearance?.primaryColour}
                        placeholder="Your primary colour"
                        onBlur={(e) => {
                          setModifiedAppearance({
                            ...modifiedAppearance,
                            primaryColour: e.target.value,
                          });
                          setChangesMade(true);
                        }}
                      ></input>
                    </div>
                    <div className="flex column-nowrap builder-step-group builder-step-group--md">
                      <label
                        htmlFor="secondary"
                        className="input-label input-sublabel"
                      >
                        Secondary
                      </label>
                      <input
                        id="secondary"
                        type="secondary"
                        className="input"
                        defaultValue={appearance?.secondaryColour}
                        placeholder="Your secondary colour"
                        onBlur={(e) => {
                          setModifiedAppearance({
                            ...modifiedAppearance,
                            secondaryColour: e.target.value,
                          });
                          setChangesMade(true);
                        }}
                      ></input>
                    </div>
                    <div className="flex justify-content-center align-items-center visualizer">
                      <MarkieVisualizer
                        primaryColour={modifiedAppearance.primaryColour}
                        secondaryColour={modifiedAppearance.secondaryColour}
                        appearance={modifiedAppearance.appearance}
                      />
                    </div>
                  </div>
                </AccountContentBlock>
                <AccountContentBlock
                  headline="Appearance"
                  subheadline="Round or Rectangle"
                >
                  <div className="flex column-nowrap account-content-group-wrapper">
                    <div className="flex row-nowrap align-items-center builder-step-group">
                      <input
                        id="round"
                        type="radio"
                        name="appearance"
                        className="input-radio"
                        defaultChecked={appearance.appearance === "round"}
                        onChange={() => {
                          setModifiedAppearance({
                            ...modifiedAppearance,
                            appearance: "round",
                          });
                          setChangesMade(true);
                        }}
                      ></input>
                      <label
                        htmlFor="round"
                        className="input-label input-label-radio"
                      >
                        Round
                      </label>
                    </div>
                    <div className="flex row-nowrap align-items-center builder-step-group">
                      <input
                        id="rectangle"
                        type="radio"
                        name="appearance"
                        defaultChecked={appearance.appearance === "rectangle"}
                        className="input-radio"
                        onChange={() => {
                          setModifiedAppearance({
                            ...modifiedAppearance,
                            appearance: "rectangle",
                          });
                          setChangesMade(true);
                        }}
                      ></input>
                      <label
                        htmlFor="rectangle"
                        className="input-label input-label-radio"
                      >
                        Rectangle
                      </label>
                    </div>
                  </div>
                </AccountContentBlock>
              </form>
            )}
          </Fragment>
        </AccountInfoGroup>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Settings;
