import "./AccountContentBlock.css";

const AccountContentBlock = ({
  headline,
  subheadline,
  children,
  labelFor,
  className,
}: {
  headline: string;
  subheadline?: string;
  labelFor?: string;
  children: JSX.Element;
  className?: string;
}) => {
  return (
    <div
      className={`flex row-nowrap ${
        className ? className : ""
      } account-content-block`}
    >
      {labelFor ? (
        <label
          htmlFor={labelFor}
          className="flex column-nowrap input-label input-label--cursor input-label--regular input-label--account"
        >
          <span className="input-label-text">{headline}</span>
          {subheadline && (
            <span className="input-label-text input-label-text--description">
              {subheadline}
            </span>
          )}
        </label>
      ) : (
        <div className="flex column-nowrap input-label input-label--regular input-label--account">
          <span className="input-label-text">{headline}</span>
          {subheadline && (
            <span className="input-label-text input-label-text--description">
              {subheadline}
            </span>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default AccountContentBlock;
