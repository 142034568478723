import { Fragment, useEffect, useRef, useState } from "react";
import RecordRTC, { MediaStreamRecorder } from "recordrtc";
import toast from "react-hot-toast";
import * as Bowser from "bowser";

import "./MarkieRecorder.css";
import { Cross, Rotate, ScreenRecorder, Settings } from "../shared/icon/icons";
import ActionButton from "../shared/components/button/ActionButton";
import Select, { SingleValue } from "react-select";

let recorder: RecordRTC | undefined;
let mediaStream: MediaStream | undefined;
let mediaScreenStream: MediaStream | undefined;

const MarkieRecorder = ({
  fetchVideoUrl,
  setShowMarkieRecorder,
  setMarkieVideo,
  setVideoUploading,
  uploadVideo,
  closeRecorder,
  uploadingAmount,
}: {
  fetchVideoUrl: (videoName: string) => Promise<string>;
  setMarkieVideo: (videoName: string) => void;
  setShowMarkieRecorder: (value: boolean) => void;
  setVideoUploading: (value: boolean) => void;
  uploadVideo: (file: Blob) => Promise<string>;
  closeRecorder: () => void;
  uploadingAmount: number;
}) => {
  const browser = Bowser.parse(window.navigator.userAgent);
  const recorderRef = useRef<HTMLVideoElement | null>(null);
  const screenRecRef = useRef<HTMLVideoElement | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [reviewVideo, setReviewVideo] = useState<boolean>(false);
  const [videoName, setVideoName] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [processingVideo, setIsProcessingVideo] = useState<boolean>(false);
  const [showCameraSettings, setShowCameraSettings] = useState<boolean>(false);
  const [showScreenshare, setScreenshare] = useState<boolean>(false);
  const [mediaInputDevices, setMediaInputDevices] = useState<
    MediaDeviceInfo[] | null
  >(null);
  const [activeMediaDevice, setActiveMediaDevice] =
    useState<MediaDeviceInfo | null>(null);

  const getConnectedDevices = (
    type: "videoinput" | "audioinput",
    callback: (array: MediaDeviceInfo[]) => void
  ) => {
    let audio = false;

    if (
      browser.platform &&
      browser.platform.type === "desktop" &&
      browser.browser.name !== "Firefox"
    ) {
      audio = true;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 },
        },
        audio,
      })
      .then((_) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const filtered = devices.filter((device) => device.kind === type);
          callback(filtered);
        });
      })
      .catch(() => {
        console.error("Cannot connect to camera. Please contact support.");
        toast.error("Allow access to camera before continuing");
        closeRecorder();
      });
  };

  const setupCamera = (deviceId: string) => {
    let audio = false;

    if (
      browser.platform &&
      browser.platform.type === "desktop" &&
      browser.browser.name !== "Firefox"
    ) {
      audio = true;
    }

    const options = {
      video: {
        deviceId: {
          exact: deviceId,
        },
        width: { min: 1024, ideal: 1280, max: 1920 },
        height: { min: 576, ideal: 720, max: 1080 },
      },
      audio,
    };

    navigator.mediaDevices
      .getUserMedia(options)
      .then((videoStream) => {
        mediaStream = videoStream;
        const videoElement: HTMLVideoElement | null = recorderRef.current;
        if (videoElement) {
          videoElement.srcObject = videoStream;
          setupRecorder(videoElement, videoStream);
        }
      })
      .catch(() => {
        /* Camera Permission Denied */
        console.error("Cannot connect to camera. Please contact support.");
        toast.error("Allow access to camera before continuing");
        closeRecorder();
      });
  };

  const setupScreenshare = () => {
    navigator.mediaDevices
      .getDisplayMedia({
        video: true,
        audio: false,
      })
      .then((videoStream) => {
        mediaScreenStream = videoStream;
        const videoElement: HTMLVideoElement | null = screenRecRef.current;
        if (videoElement) {
          videoElement.srcObject = videoStream;
          // setupRecorder(videoElement, videoStream);
        }
      })
      .catch(() => {
        console.error("Cannot connect capture screen. Please contact support.");
        toast.error("Allow access to share screen before continuing");
        setScreenshare(false);
      });
  };

  const setupRecorder = (element: HTMLVideoElement, stream: MediaStream) => {
    recorder = new RecordRTC(stream, {
      type: "video",
      disableLogs: true,
      video: element,
      recorderType: MediaStreamRecorder,
      mimeType: "video/mp4",
    });
  };

  const handleStartRecording = () => {
    if (!recorder) return;
    recorder.startRecording();
    setIsRecording(true);
  };

  const handleStopRecording = async () => {
    if (!recorder) return;
    recorder.stopRecording(async () => {
      if (!recorder) return;
      const file = recorder.getBlob();
      setIsProcessingVideo(true);
      await uploadVideo(file)
        .catch((_) =>
          toast.error("Error uploading video. Please contact support")
        )
        .then((name) => setVideoName(name));
      setIsProcessingVideo(false);
      setIsRecording(false);
      setReviewVideo(true);
    });
  };

  const handleRecording = async () => {
    if (isRecording) {
      await handleStopRecording();
    } else if (recorder) {
      handleStartRecording();
    } else {
      console.error("No recorder");
    }
  };

  useEffect(() => {
    if (
      mediaInputDevices &&
      mediaInputDevices.length > 0 &&
      activeMediaDevice === null
    ) {
      setActiveMediaDevice(mediaInputDevices[0]);
    }
  }, [mediaInputDevices]);

  useEffect(() => {
    if (activeMediaDevice) setupCamera(activeMediaDevice.deviceId);
  }, [activeMediaDevice]);

  useEffect(() => {
    if (reviewVideo && videoName) {
      fetchVideoUrl(videoName).then((url) => setVideoUrl(url));
    }
    setIsProcessingVideo(false);
  }, [reviewVideo, videoName]);

  useEffect(() => {
    getConnectedDevices("videoinput", (cameras: MediaDeviceInfo[]) =>
      setMediaInputDevices(cameras)
    );
    // MediaStream is undefined the first time the components opens, but not a second time
    return () => {
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }
    };
  }, []);

  console.log("MediaStream", mediaStream);

  return (
    <div className="markie-recorder-wrapper">
      {!reviewVideo && (
        <div
          className={`flex column-nowrap align-items-center justify-content-flex-end markie-recorder-content markie-recorder-content-${
            isRecording ? "active" : "inactive"
          }`}
        >
          {browser.platform &&
            browser.platform.type === "desktop" &&
            browser.browser.name === "Firefox" && (
              <div className="flex markie-recommendation">
                Browser not supported, use Chrome for audio recording
              </div>
            )}
          {browser.platform &&
            browser.platform.type === "desktop" &&
            browser.browser.name === "Safari" && (
              <div className="flex markie-recommendation">
                Browser not supported, use Chrome instead
              </div>
            )}
          {showCameraSettings &&
            mediaInputDevices &&
            mediaInputDevices.length > 1 &&
            activeMediaDevice && (
              <div className="flex column-nowrap camera-settings">
                <div className="flex column-nowrap media-input-group">
                  <label
                    htmlFor="markie-video-input"
                    className="markie-script-headline"
                  >
                    Choose a camera
                  </label>
                  <Select
                    value={{
                      label: activeMediaDevice.label,
                      value: activeMediaDevice.deviceId,
                    }}
                    options={mediaInputDevices.map((comp) => ({
                      label: comp.label,
                      value: comp.deviceId,
                    }))}
                    onChange={(
                      data: SingleValue<{
                        label: string;
                        value: string;
                      }>
                    ) => {
                      if (!data) return;
                      const device = mediaInputDevices.find(
                        (device) => device.deviceId === data.value
                      );
                      if (device) {
                        setActiveMediaDevice(device);
                      }
                      setShowCameraSettings(false);
                    }}
                    className="react-select-container react-select-container--full"
                    classNamePrefix="react-select"
                  />
                  <div className="flex spacer spacer--sm"></div>
                </div>
              </div>
            )}
          <button
            className="flex justify-content-center align-items-center markie-recorder-cirlce-button markie-recorder-close"
            onClick={() => {
              setShowMarkieRecorder(false);
            }}
          >
            <Cross fill="white" width={32} height={32} />
          </button>
          {mediaInputDevices && mediaInputDevices.length > 1 && (
            <button
              className="flex justify-content-center align-items-center markie-recorder-cirlce-button markie-recorder-settings"
              onClick={() => {
                setShowCameraSettings((val) => !val);
              }}
            >
              <Settings fill="white" />
              <span className="button-text">Settings</span>
            </button>
          )}
          <button
            className={`flex justify-content-center align-items-center markie-recorder-cirlce-button markie-recorder-screen ${
              showScreenshare ? "markie-recorder-screen--cancel" : ""
            }`}
            onClick={() => {
              setScreenshare((val) => !val);
              if (!showScreenshare) {
                setupScreenshare();
              }
            }}
          >
            <ScreenRecorder fill="white" />
            <span className="button-text">
              {showScreenshare ? "Cancel" : "Share screen"}
            </span>
          </button>
          <button
            className="flex markie-recorder-button-wrapper"
            onClick={handleRecording}
          >
            <div
              className={`markie-recorder-button markie-recorder-button-${
                isRecording ? "active" : "inactive"
              }`}
            />
          </button>
          {!showScreenshare && (
            <Fragment>
              <div className="markie-video-line line-vertical-center"></div>
              <div className="markie-video-line line-horizontal-center"></div>
            </Fragment>
          )}
          {showScreenshare && (
            <div className="flex markie-video-display-wrapper markie-video-display--screen">
              <div className="gradient gradient-top" />
              <div className="gradient gradient-bottom" />
              <video
                autoPlay
                playsInline
                loop
                muted
                ref={screenRecRef}
                className={`markie-video-display markie-video-display-screenshare`}
              />
            </div>
          )}
          <div
            className={`flex markie-video-display-wrapper ${
              showScreenshare ? "markie-video-display--small" : ""
            }`}
          >
            <div className="gradient gradient-top" />
            <div className="gradient gradient-bottom" />
            <video
              ref={recorderRef}
              autoPlay
              playsInline
              loop
              muted
              className={`markie-video-display`}
            />
          </div>
        </div>
      )}
      {processingVideo && (
        <Fragment>
          <div
            className={`flex column-nowrap align-items-center justify-content-center markie-recorder-content markie-recorder-content-inactive`}
          >
            <div className="recorder-content-headline">Processing video</div>
            <div className="recorder-content-loader-bar">
              <div
                className="recorder-content-loader-bar-inner"
                style={{
                  transform: `scaleX(${uploadingAmount})`,
                }}
              ></div>
            </div>
          </div>
        </Fragment>
      )}
      {reviewVideo && videoName && videoUrl && (
        <div
          className={`flex column-nowrap align-items-center justify-content-flex-end markie-recorder-content markie-recorder-content-inactive`}
        >
          <button
            className="flex justify-content-center align-items-center markie-recorder-close"
            onClick={() => {
              setShowMarkieRecorder(false);
            }}
          >
            <Cross fill="white" width={32} height={32} />
          </button>
          <div className="gradient gradient-top" />
          <div className="flex markie-video-buttons">
            <ActionButton
              version="primary"
              type="sm"
              label="Use this clip"
              onClick={() => {
                setMarkieVideo(videoName);
                setShowMarkieRecorder(false);
              }}
              stretch
            />
            <div className="flex spacer spacer--horizontal--sm"></div>
            <ActionButton
              version="secondary"
              type="sm"
              icon={<Rotate />}
              label="Retry"
              onClick={() => {
                setReviewVideo(false);
                if (activeMediaDevice) setupCamera(activeMediaDevice?.deviceId);
              }}
              stretch
            />
          </div>
          <video
            controls
            className="markie-video-display markie-video-recorded"
            src={videoUrl}
          />
        </div>
      )}
      <div
        className="markie-recorder-background"
        onClick={() => {
          setShowMarkieRecorder(false);
        }}
      ></div>
    </div>
  );
};

export default MarkieRecorder;
