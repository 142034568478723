import { Markie } from "./AddMarkie";
import MarkieCard from "./MarkieCard";
import "./MarkieCards.css";

const MarkieCards = ({ markies }: { markies: Markie[] }) => {
  return (
    <div className="flex row-wrap markie-cards">
      {markies.map((markie: Markie) => {
        return <MarkieCard key={markie._id} markie={markie} />;
      })}
    </div>
  );
};

export default MarkieCards;
