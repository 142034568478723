import "./Prompt.css";

const Prompt = ({
  title,
  text,
  children,
  onBackgroundClick,
}: {
  title: string;
  text: string;
  children?: JSX.Element | JSX.Element[];
  onBackgroundClick: () => void;
}) => {
  return (
    <div className="flex prompt">
      <div className="flex column-nowrap align-items-center prompt-content">
        <div className="prompt-headline">{title}</div>
        <p className="prompt-text">{text}</p>
        {children}
      </div>
      <div className="background" onClick={onBackgroundClick} />
    </div>
  );
};

export default Prompt;
