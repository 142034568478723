import "./DashboardNavigation.css";
import * as Bowser from "bowser";
import { NavLink } from "react-router-dom";
import Logo from "../logo/Logo";

import Account from "../account/Account";
import SidebarNavigation from "./SidebarNavigation";
import MobileNotSupported from "../../../account/MobileNotSupported";
import Prompt from "../prompt/Prompt";
import ActionButton from "../button/ActionButton";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const DashboardNavigation = () => {
  const [validAccount, setValidAccount] = useState<boolean>(true);
  const accountId = useSelector((state: RootState) => state.dashboard.id);
  const browser = Bowser.parse(window.navigator.userAgent);

  useEffect(() => {
    if (accountId) {
      axios
        .get(apiUrl("/account/valid"), { withCredentials: true })
        .then(({ data }) => {
          setValidAccount(data);
        });
    }
  }, [accountId]);

  return (
    <div className="flex nav-wrapper">
      <div className="flex row-nowrap align-items-center nav-bar">
        <div className="flex row-nowrap align-items-center justify-content-space-between logo-with-nav">
          <NavLink to={"/"}>
            <Logo />
          </NavLink>
          <div className="flex row-nowrap align-items-center aside">
            <Account />
          </div>
        </div>
      </div>

      {browser.platform.type !== "desktop" && <MobileNotSupported />}
      {browser.platform.type === "desktop" && <SidebarNavigation />}
      {!validAccount && (
        <Prompt
          title={"Out of Trial"}
          text={`You're trial is expired. Upgrade to continue using Markie.`}
          onBackgroundClick={() => {}}
        >
          <div className="flex row-nowrap prompt-actions">
            <ActionButton
              onClick={() => {
                window.location.href = "mailto:hello@getmarkie.io";
              }}
              type="md"
              version="primary"
              label="Upgrade"
              stretch
            />
          </div>
        </Prompt>
      )}
    </div>
  );
};

export default DashboardNavigation;
