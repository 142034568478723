import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import OnboardingStep from "./OnboardingStep";
import OnboardingStepContent from "./OnboardingStepContent";

import "./AccountOnboarding.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { fetchId } from "../shared/fetch";
import { updateId } from "../slice";

const AccountOnboarding = () => {
  const dispatch = useDispatch();
  const accountID = useSelector((state: RootState) => state.dashboard.id);
  const [script, setScript] = useState<string | null>(null);

  useEffect(() => {
    fetchId().then((id) => dispatch(updateId(id)));
  }, [accountID]);

  useEffect(() => {
    if (accountID) {
      setScript(
        `<script>
  (function (w, d, s, o, f, m, js, fjs) {
    w["MarkieWidget"] = o;
    w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments); };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]); 
    js.id = o; js.src = f; js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
    w[m] = w[m] || { boot: (u) => w.addEventListener('load', () => w[m].boot(u)) };
  })(window, document, "script", "mrkw", "https://widget.getmarkie.io", "markie");
  mrkw("init", "${accountID}");
  window.markie.boot("unique-anonymized-random-string");
</script>
`
      );
    }
  }, [accountID]);

  return (
    <div className="flex row-nowrap account-onboarding">
      <Tabs className="flex row-nowrap justify-content-space-between onboarding-wrapper">
        <TabList className="flex column-nowrap onboarding-list">
          <Tab
            className="flex flex align-items-center onboarding-list-item"
            selectedClassName="onboarding-list-item--active"
          >
            <OnboardingStep
              step="1"
              headline="Create first Markie"
              description="2 minutes"
              success={false}
            />
          </Tab>
          <Tab
            className="flex flex align-items-center onboarding-list-item"
            selectedClassName="onboarding-list-item--active"
          >
            <OnboardingStep
              step="2"
              headline="Add custom branding"
              description="30 seconds"
              success={false}
            />
          </Tab>
          <Tab
            className="flex flex align-items-center onboarding-list-item"
            selectedClassName="onboarding-list-item--active"
          >
            <OnboardingStep
              step="3"
              headline="Add logo"
              description="30 seconds"
              success={false}
            />
          </Tab>
          <Tab
            className="flex flex align-items-center onboarding-list-item"
            selectedClassName="onboarding-list-item--active"
          >
            <OnboardingStep
              step="4"
              headline="Install Markie"
              description="30 seconds"
              success={false}
            />
          </Tab>
        </TabList>
        <TabPanel
          className="onboarding-content"
          selectedClassName="onboarding-content--active"
        >
          <OnboardingStepContent
            headline="Create your first Markie"
            text={[
              {
                text: "Onboard your customer through your first video.",
              },
            ]}
            action={{
              label: "Create first Markie",
              to: "/markie/new",
              type: "link",
            }}
          />
        </TabPanel>
        <TabPanel
          className="onboarding-content"
          selectedClassName="onboarding-content--active"
        >
          <OnboardingStepContent
            headline="Add custom branding"
            text={[
              {
                text: "Add your branding colours to make it look great in your interface.",
              },
            ]}
            action={{
              label: "Add branding",
              to: "/settings",
              type: "link",
            }}
          />
        </TabPanel>
        <TabPanel
          className="onboarding-content"
          selectedClassName="onboarding-content--active"
        >
          <OnboardingStepContent
            headline="Add logo"
            text={[
              {
                text: "To make it look yours even more, we enable you to add your logo.",
              },
            ]}
            action={{
              label: "Add logo",
              to: "/settings",
              type: "link",
            }}
          />
        </TabPanel>
        <TabPanel
          className="onboarding-content"
          selectedClassName="onboarding-content--active"
        >
          {script && (
            <OnboardingStepContent
              headline="Install Markie"
              text={[
                {
                  text: "1. Copy and paste the script before the </body>.",
                },
                {
                  text: "2. Copy and paste the 'boot' snippet.",
                },
              ]}
              code={script}
            />
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AccountOnboarding;
