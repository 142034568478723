import { useNavigate } from "react-router";
import InternalButton from "../shared/components/button/InternalButton";
import { Markie } from "./AddMarkie";

import "./MarkieCard.css";

const MarkieCard = ({ markie }: { markie: Markie }) => {
  const navigate = useNavigate();

  return (
    <div
      key={markie._id}
      className={`flex column-nowrap markie-card`}
      onClick={() => {
        navigate(`/markie/${markie._id}`);
      }}
    >
      <div className="flex column-nowrap markie-card-header">
        <div className="flex row-nowrap markie-card-badges">
          <div className="flex row-nowrap align-items-center align-self-flex-start markie-state-wrapper">
            <div
              className={`markie-state markie-state--compact markie-state`}
            ></div>
            <span className="markie-state-text">{markie.trigger}</span>
          </div>
          {markie.htmlElementID && (
            <div className="flex row-nowrap align-items-center align-self-flex-start markie-state-wrapper">
              <div
                className={`markie-state markie-state--compact markie-state`}
              ></div>
              <span className="markie-state-text">
                {markie.htmlElementSelector}
                {markie.htmlElementID}
              </span>
            </div>
          )}
        </div>
        <div className="markie-card-headline">
          {markie.title || "Untitled Markie"}
        </div>
        <div className="markie-card-description">
          Created at {new Date(markie.createdAt).toLocaleDateString()}
        </div>
      </div>
      <div className="flex column-nowrap markie-card-content">
        <div className="flex justify-content-space-between align-items-center">
          <InternalButton
            version="primary"
            type="sm"
            label="Edit"
            to={`/markie/${markie._id}`}
            className="web-button--card"
          />
        </div>
      </div>
    </div>
  );
};

export default MarkieCard;
