const MobileNotSupported = () => {
  return (
    <div className="flex column-nowrap align-items-center justify-content-center mobile-not-supported">
      <div className="flex row-nowrap justify-content-center mobile-not-supported-wrapper">
        <div className="text">We suggest using Markie on Desktop</div>
      </div>
    </div>
  );
};

export default MobileNotSupported;
