import "./Loader.css";

const Loader = ({
  text,
  version = "default",
  size = "md",
  className,
}: {
  text?: string;
  version?: "default" | "light" | "dark";
  size?: "sm" | "md" | "lg";
  className?: string;
}) => {
  return (
    <div
      className={`flex row-nowrap align-items-center loader loader--${version} loader--${size} ${
        className ? className : ""
      }`}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox="0 0 17 17"
        className="loader-icon"
      >
        <g id="loader-icon-circle-group">
          <circle
            id="circ"
            className="loader-icon-circle"
            cx="8.5"
            cy="8.5"
            r="7"
          />
          <path
            id="path"
            className="loader-icon-path"
            d="M15.5,8.5c0-3.9-3.1-7-7-7"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 8.5 8.5"
              to="360 8.5 8.5"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
          </path>
        </g>
      </svg>
      {text && <p className="loader-text">{text}</p>}
    </div>
  );
};

export default Loader;
