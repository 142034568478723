import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { apiUrl, useQueryString } from "../shared/utils";
import "./Login.css";
import "./Enable2FA.css";
import Logo from "../shared/components/logo/Logo";
import ExternalButton from "../shared/components/button/ExternalButton";
import { Link } from "react-router-dom";

const Auth2FA = ({ email, password }: { email: string; password: string }) => {
  const navigate = useNavigate();
  const code2faRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (code2faRef.current) code2faRef.current.focus();
  }, []);

  const login2fa = (code: string) => {
    axios
      .post(
        apiUrl("auth/login-2fa"),
        { email, password, code },
        { withCredentials: true }
      )
      .then((_) => navigate(`/overview`))
      .catch((_) => toast.error("Invalid code. Try again"));
  };

  const handleTwoFactorSubmit = (e: any) => {
    e.preventDefault();
    if (code2faRef.current) login2fa(code2faRef.current.value);
  };

  return (
    <section className="flex attention-wrapper">
      <form
        onSubmit={handleTwoFactorSubmit}
        className="flex column-nowrap account-form-two-factor-authentifcation"
      >
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="2fa" className="input-label">
            Two factor authentication
          </label>
          <input
            id="2fa"
            type="text"
            className="input"
            autoFocus
            ref={code2faRef}
            placeholder="Your code"
          ></input>
        </div>
        <div className="flex spacer spacer--sm"></div>
        <button
          type="submit"
          className="web-button web-button--primary web-button--sm web-button--stretch"
        >
          Submit
        </button>
      </form>
    </section>
  );
};

const LoginForm = () => {
  const navigate = useNavigate();
  const query = useQueryString();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [show2FA, setShow2FA] = useState<boolean>(false);
  const [showLoginExpired] = useState<boolean>(
    () => query.get("expired") !== null
  );
  const redirectUrl = query.get("redirect_url");

  useEffect(() => {
    if (redirectUrl) {
      // Cookies.set(openflowLoginRedirectCookie, redirectUrl);
    }
    // Check if user is already logged in
    /*
    axios
      .get(apiUrl("/auth/check"), { withCredentials: true })
      .then((_) => navigate("/overview"));
      */
  }, [redirectUrl]);

  const login = (email: string, password: string) => {
    axios
      .post(
        apiUrl("auth/login"),
        { email, password },
        { withCredentials: true }
      )
      .then(({ data: { require_2fa } }) => {
        if (require_2fa) setShow2FA(true);
        else navigate("/overview");
      })
      .catch((_) => {
        toast.error("Error logging in");
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (emailRef.current && passwordRef.current)
      login(emailRef.current.value, passwordRef.current.value);
  };

  return (
    <div className="flex column-nowrap account-form-wrapper">
      <header className="flex justify-content-center account-form-header">
        <h1 className="account-form-headline">Login</h1>
      </header>
      {showLoginExpired && (
        <Fragment>
          <div className="flex column-nowrap align-items-center attention-wrapper">
            <p className="attention-wrapper-text attention-wrapper-text--center">
              You were automatically logged out. <br /> Please log in to
              continue.
            </p>
          </div>
          <div className="flex spacer"></div>
        </Fragment>
      )}
      <form className="flex column-nowrap account-form" onSubmit={handleSubmit}>
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="email" className="input-label">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="input"
            autoFocus
            ref={emailRef}
            placeholder="Your email"
          ></input>
        </div>
        <div className="flex column-nowrap builder-step-group builder-step-group--md">
          <label htmlFor="password" className="input-label">
            Password
          </label>
          <input
            id="password"
            type="password"
            className="input"
            ref={passwordRef}
            placeholder="Your password"
          ></input>
        </div>
        <div className="flex spacer"></div>
        <button
          type="submit"
          className="web-button web-button--primary web-button--md web-button--stretch"
        >
          Login
        </button>
      </form>
      {show2FA && (
        <Auth2FA
          email={emailRef.current?.value || ""}
          password={passwordRef.current?.value || ""}
        />
      )}
      <div className={`flex column-nowrap align-items-center divider`}>
        <p className="divider-text">or</p>
        <div className="line"></div>
      </div>
      <ExternalButton
        version="secondary"
        type="md"
        to={apiUrl("auth/google")}
        label="Login with Google"
        target="self"
        stretch
      />
      <div className="flex spacer"></div>
      <p className="flex justify-content-center sign-up-info">
        <span className="signup-info-text">Don't have an account?</span>
        <a
          href="https://getmarkie.io/pricing"
          className="signup-info-text signup-info-text--link"
        >
          Get one here!
        </a>
      </p>
    </div>
  );
};

const Login = () => {
  return (
    <div className="flex login-wrapper">
      <div className="flex column-nowrap align-items-center login-container">
        <div className="flex justify-content-center login-logo-wrapper">
          <Logo />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
