import { useState, ReactNode } from "react";
import "./Accordion.css";

const Accordion = ({
  headline,
  isHighlighted,
  open,
  children,
}: {
  headline: string;
  isHighlighted?: boolean;
  open?: boolean;
  children: ReactNode;
}) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <div
      className={`flex column-nowrap accordion-item accordion-item--${
        open || active ? "active" : "inactive"
      }`}
    >
      <div
        onClick={() => {
          setActive(!active);
        }}
        className={`flex row-nowrap align-items-center justify-content-space-between accordion-item-header ${
          isHighlighted ? "accordion-item-header--hightlight" : ""
        }`}
      >
        <h1 className={`accordion-item-headline`}>{headline}</h1>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={`accordion-item-chevron accordion-item-chevron--${
            open || active ? "active" : "inactive"
          }`}
        >
          <path
            fillRule="evenodd"
            d="M16 8L11.737 12.263 7.473 8 6 9.472 11.737 15.209 17.472 9.472z"
          />
        </svg>
      </div>
      <div
        className={`column-nowrap accordion-item-content accordion-item-content--${
          open || active ? "active" : "inactive"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
