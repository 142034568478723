import { Fragment, useEffect } from "react";
import hljs from "highlight.js";

import ActionButton from "../shared/components/button/ActionButton";
import InternalButton from "../shared/components/button/InternalButton";

import "./OnboardingStepContent.css";

const OnboardingStepContent = ({
  headline,
  text,
  action,
  video,
  code,
}: {
  headline: string;
  text: Record<string, any>;
  action?: Record<string, any>;
  video?: Record<string, any>;
  code?: string;
}) => {
  useEffect(() => {
    document
      .querySelectorAll(".markie-script-code pre code")
      .forEach((block: any) => {
        hljs.highlightElement(block);
      });
  }, []);

  return (
    <div className="flex column-nowrap align-items-flex-start onboarding-list-item-content">
      <div className="onboarding-list-item-content-headline">{headline}</div>

      {text && text.length !== 0 && (
        <div className="onboarding-list-item-content-description">
          {text.map(({ text }: { text: string }, idx: number) => {
            return (
              <p
                key={`${headline}-${idx}`}
                className="flex onboarding-list-item-content-text"
              >
                {text}
              </p>
            );
          })}
        </div>
      )}

      {code && (
        <div className="markie-script-code">
          <pre>
            <code
              className="hljs language-javascript"
              dangerouslySetInnerHTML={{
                __html: code.replace(/</g, "&lt;").replace(/>/g, "&gt;"),
              }}
            />
          </pre>
        </div>
      )}

      {action && action.type === "link" && (
        <InternalButton
          to={action.to}
          version="primary"
          type="sm"
          label={action.label}
          className="mrk-hover-button"
        />
      )}

      {action && action.type === "click" && (
        <ActionButton
          onClick={action.onClick}
          version="primary"
          type="sm"
          label={action.label}
        />
      )}
      {video && video.link && (
        <Fragment>
          <div className="flex spacer--md"></div>
          <div className="onboarding-list-item-content-subheadline">
            Watch tutorial
          </div>
          <a
            href={video.link}
            className="flex onboarding-list-item-content-link"
            target={"_blank"}
            rel="noreferrer"
          >
            <img
              src={video.thumb}
              alt={video.alt}
              width={1280}
              height={720}
              className="tutorial-thumbnail"
            />
          </a>
        </Fragment>
      )}
    </div>
  );
};

export default OnboardingStepContent;
