import AccountModal from "./AccountModal";
import "./Account.css";

const Account = () => {
  return (
    <div className="flex row-nowrap align-items-center account">
      <div className="flex align-items-center justify-content-center thumbnail">
        <span className="cool-kids">😎</span>
      </div>
      <AccountModal />
    </div>
  );
};

export default Account;
