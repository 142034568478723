import { NavLink } from "react-router-dom";
import "./NavItem.css";

const NavItem = ({
  icon,
  label,
  link,
  isExternal,
  className,
}: {
  icon?: JSX.Element;
  label: string;
  link: string;
  isExternal?: boolean;
  className?: string;
}) => {
  const renderDom = () => {
    if (isExternal) {
      return (
        <a
          href={link}
          className={`flex row-nowrap align-items-center nav-list-item ${
            className ? className : ""
          }`}
        >
          {icon && (
            <span className="flex justify-content-center align-items-center nav-list-item-icon">
              {icon}
            </span>
          )}

          <span className="nav-list-item-label">{label}</span>
        </a>
      );
    } else {
      return (
        <NavLink
          to={link}
          className={(data) =>
            data.isActive
              ? `flex row-nowrap align-items-center nav-list-item nav-list-item--active ${
                  className ? className : ""
                }`
              : `flex row-nowrap align-items-center nav-list-item ${
                  className ? className : ""
                }`
          }
        >
          {icon && (
            <span className="flex justify-content-center align-items-center nav-list-item-icon">
              {icon}
            </span>
          )}

          <span className="nav-list-item-label">{label}</span>
        </NavLink>
      );
    }
  };
  return (
    <li
      className={`flex row-nowrap nav-list-item-wrapper ${
        className ? className : ""
      }`}
    >
      {renderDom()}
    </li>
  );
};

export default NavItem;
