import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import "./AccountModal.css";
import ActionButton from "../button/ActionButton";
import { Buoy, Feedback } from "../../icon/icons";
import { apiUrl } from "../../utils";
import AccountMenuItem from "./AccountMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { fetchUsername } from "../../fetch";
import { updateUser } from "../../../slice";

const AccountModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const username = useSelector((state: RootState) => state.dashboard.userName);

  const handleLogOut = async () => {
    await axios.get(apiUrl("auth/logout"), { withCredentials: true });
    navigate("/login");
  };

  useEffect(() => {
    if (!username) {
      fetchUsername().then((username) => dispatch(updateUser(username)));
    }
  }, [username]);

  return (
    <Fragment>
      <div className="column-nowrap account-modal">
        <div className="flex align-items-center account-modal-header">
          <span className="account-modal-name">
            {username && username !== "" ? username : "User"}
          </span>
        </div>
        <ul className="flex column-nowrap account-modal-list">
          <li className="flex account-modal-list-item">
            <AccountMenuItem
              icon={<Buoy fill="#a1a1a1" />}
              label="Help &amp; support"
              onClick={() => {
                /* @ts-ignore:disable-next-line */
                $crisp.push(["do", "chat:show"]);
                /* @ts-ignore:disable-next-line */
                $crisp.push(["do", "chat:open"]);
              }}
            />
          </li>
        </ul>
        <ul className="flex column-nowrap account-modal-list">
          <li className="flex account-modal-list-item">
            <ActionButton
              version="secondary"
              type="sm"
              label={"Logout"}
              onClick={() => {
                handleLogOut();
              }}
              stretch
            />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default AccountModal;
