import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import DashboardNavigation from "../shared/components/account/DashboardNavigation";
import PageWrapper from "../shared/components/page/PageWrapper";
import ContentWrapper from "../shared/components/page/ContentWrapper";
import AccountInfoGroup from "./AccountInfoGroup";
import AccountOnboarding from "./AccountOnboarding";

import { fetchAppearance, fetchMarkies } from "../shared/fetch";
import { updateAppearance, updateMarkie } from "../slice";
import { updateMarkies } from "./Markies";

import "./Overview.css";

const Overview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") navigate("/overview");
  }, []);

  useEffect(() => {
    fetchAppearance().then((appearance) =>
      dispatch(updateAppearance(appearance))
    );
    updateMarkies(dispatch);
  }, []);

  return (
    <PageWrapper bgColour="light-blue">
      <DashboardNavigation />
      <ContentWrapper headline="Overview">
        <AccountInfoGroup
          headline={"The basics"}
          description="Get started with Markie"
        >
          <AccountOnboarding />
        </AccountInfoGroup>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Overview;
