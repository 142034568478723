import DashboardNavigation from "../shared/components/account/DashboardNavigation";
import PageWrapper from "../shared/components/page/PageWrapper";
import ContentWrapper from "../shared/components/page/ContentWrapper";
import AccountInfoGroup from "./AccountInfoGroup";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import AccountContentBlock from "./AccountContentBlock";
import toast from "react-hot-toast";
import { apiUrl } from "../shared/utils";
import axios from "axios";
import hljs from "highlight.js";
import Select, { SingleValue } from "react-select";

import { useNavigate, useParams } from "react-router";
import { QuestionMark, Trash } from "../shared/icon/icons";
import ActionButton from "../shared/components/button/ActionButton";
import Prompt from "../shared/components/prompt/Prompt";
import MarkieRecorder from "./MarkieRecorder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { fetchId } from "../shared/fetch";
import { updateId } from "../slice";
import Accordion from "../shared/components/accordion/Accordion";
import "./AddMarkie.css";

export interface Analytics {
  displayed: number;
  unmuted: number;
  hidden: number;
  fullscreen: number;
}

export interface Markie {
  _id: string;
  title: string;
  instructions: string;
  videoName: string;
  trigger: "static" | "hover" | "click";
  htmlElementSelector: "#" | "." | "";
  htmlElementID: string;
  visibility: "published" | "draft";
  createdAt: number;
  playOnce?: boolean;
  analytics?: Analytics;
}

interface Comp {
  display: string;
  value: "#" | "." | "";
}
const elementComp: Comp[] = [
  { display: "#id", value: "#" },
  { display: ".class", value: "." },
  { display: "", value: "" },
];
const selectorDisplay = {
  "#": "#id",
  ".": ".class",
  "": "",
};

const AddMarkie = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountID = useSelector((state: RootState) => state.dashboard.id);
  const [isVideoUploading, setVideoUploading] = useState<boolean>(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const [showMarkieRecorder, setShowMarkieRecorder] = useState<boolean>(false);
  const [markieVideo, setMarkieVideo] = useState<string | null>(null);
  const [markieVideoUrl, setMarkieVideoUrl] = useState<string | null>(null);
  const [script, setScript] = useState<string | null>(null);
  const [uploadingAmount, setUploadingAmount] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);

  const { markieId } = useParams();

  const [newMarkie, setNewMarkie] = useState<Markie | null>(null);

  useEffect(() => {
    fetchId().then((id) => dispatch(updateId(id)));
  }, [accountID]);

  useEffect(() => {
    if (accountID) {
      setScript(
        `<script>
  (function (w, d, s, o, f, m, js, fjs) {
    w["MarkieWidget"] = o;
    w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments); };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]); 
    js.id = o; js.src = f; js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
    w[m] = w[m] || { boot: (u) => w.addEventListener('load', () => w[m].boot(u)) };
  })(window, document, "script", "mrkw", "https://widget.getmarkie.io", "markie");
  mrkw("init", "${accountID}");
  window.markie.boot("unique-anonymized-random-string");
</script>
`
      );
    }
  }, [accountID]);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (!newMarkie) {
      toast.error("Error creating Markie. Please contact support.");
      return;
    }

    if (!markieVideo) {
      toast.error("Please record or upload a video");
      return;
    }

    if (newMarkie.htmlElementID.replaceAll(" ", "").length === 0) {
      toast.error("Please enter a valid HTML element");
      return;
    }

    if (newMarkie) {
      const { createdAt, ...content } = newMarkie;
      await axios
        .post(apiUrl("markie/save"), content, {
          withCredentials: true,
        })
        .then((_) => {
          toast.success("Saved");
          navigate("/markies");
        })
        .catch((e) => {
          toast.error(`Error saving markig: ${e.response.data.code}`);
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (
      newMarkie &&
      newMarkie.trigger !== "static" &&
      newMarkie.htmlElementSelector === ""
    ) {
      setNewMarkie({ ...newMarkie, htmlElementSelector: "#" });
    }
  }, [newMarkie]);

  useEffect(() => {
    if (markieId === "new") {
      setNewMarkie({
        _id: "new",
        title: "",
        instructions: "",
        videoName: "",
        trigger: "click",
        htmlElementSelector: "#",
        htmlElementID: "",
        visibility: "published",
        createdAt: 0,
        playOnce: false,
      });
      setMarkieVideo(null);
    } else {
      axios
        .get(apiUrl(`markie/fetch/${markieId}`), { withCredentials: true })
        .then(({ data }) => setNewMarkie(data))
        .catch(() => toast.error("Markie not found"));
    }
  }, [markieId]);

  useEffect(() => {
    if (markieVideo) {
      fetchVideoLink(markieVideo).then((url) => setMarkieVideoUrl(url));
      if (newMarkie) {
        setNewMarkie({
          ...newMarkie,
          videoName: markieVideo,
        });
      }
    } else {
      setMarkieVideoUrl(null);
    }
  }, [markieVideo]);

  useEffect(() => {
    if (newMarkie && newMarkie.videoName !== "") {
      setMarkieVideo(newMarkie.videoName);
    }
  }, [newMarkie]);

  useEffect(() => {
    /* weird hack to init the hljs */
    setTimeout(() => {
      document
        .querySelectorAll(".markie-script-code pre code")
        .forEach((block: any) => {
          hljs.highlightElement(block);
        });
    }, 500);
  }, []);

  const deleteMarkie = async (markieId: string) => {
    await axios
      .delete(apiUrl(`markie/delete/${markieId}`), { withCredentials: true })
      .then(() => {
        toast.success("Markie deleted");
        navigate("/markies");
      })
      .catch((e) => {
        toast.error(`Error deleting markie: ${e.response.data.code}`);
      });
  };

  const deleteVideo = async () => {
    if (markieVideo) {
      setMarkieVideo(null);
      setMarkieVideoUrl(null);
    } else {
      toast.error("No video to delete");
    }
  };

  const fetchVideoLink = async (videoName: string) => {
    const url = await axios
      .post(
        apiUrl("account/video/presigned-url-get"),
        { videoName },
        { withCredentials: true }
      )
      .then(({ data }) => data)
      .catch((_) =>
        toast.error("Cannot find uploaded video. Please contact support")
      );
    return url;
  };

  const uploadVideo = async (file: Blob) => {
    const { url, name } = await axios
      .get(apiUrl("account/video/presigned-url-put"), {
        withCredentials: true,
      })
      .then(({ data }) => data)
      .catch((_) => {
        toast.error(
          "Error uploading video: could not fetch link. Please contact support"
        );
      });

    await axios
      .put(url, file, {
        headers: {
          "x-amz-meta-original": "true",
        },
        onUploadProgress(progressEvent) {
          const { loaded, total } = progressEvent;
          setUploadingAmount(loaded / total);
        },
      })
      .catch((e) => {
        toast.error("Error uploading video. Please contact support");
      });
    await axios
      .post(
        apiUrl("account/video/confirm-upload"),
        { video: name },
        { withCredentials: true }
      )
      .then((_) => toast.success("Upload successful"))
      .catch((_) => {
        toast.error("Error uploading video. Please contact support");
      });
    setVideoUploading(false);
    setUploadingAmount(0);
    return name;
  };

  const uploadVideoFromDesktop = async (event: ChangeEvent) => {
    setVideoUploading(true);
    const { files } = event.target as HTMLInputElement;
    if (!files || files.length === 0) return;
    const file = files[0];
    const name = await uploadVideo(file);
    setMarkieVideo(name);
  };

  const openMarkieRecorder = () => {
    setShowMarkieRecorder(true);
  };

  return (
    <PageWrapper bgColour="light-blue">
      <DashboardNavigation />
      <ContentWrapper headline="Add Markie">
        <AccountInfoGroup
          headline={"New Markie"}
          description="Add a new video onboarding"
        >
          <Fragment>
            {newMarkie && (
              <Fragment>
                <form
                  className="flex column-nowrap add-markie-wrapper"
                  onSubmit={handleFormSubmit}
                >
                  <div className="flex row-nowrap justify-content-flex-end align-items-center account-content-block">
                    {newMarkie._id !== "new" && (
                      <Fragment>
                        <ActionButton
                          icon={<Trash fill="#fff" />}
                          version="error"
                          type="xs"
                          onClick={() => {
                            setShowDeletePrompt(true);
                          }}
                        />
                        <div className="flex spacer spacer--horizontal spacer--horizontal--md">
                          <div
                            className="verical-ruler"
                            style={{
                              height: 32,
                            }}
                          ></div>
                        </div>
                      </Fragment>
                    )}
                    <ActionButton
                      version="secondary"
                      type="sm"
                      label="Cancel"
                      onClick={() => {
                        navigate("/markies");
                      }}
                    />
                    <div className="flex spacer spacer--horizontal spacer--horizontal--sm"></div>
                    <button
                      disabled={saving}
                      type="submit"
                      className="web-button web-button--primary web-button--sm"
                    >
                      Save
                    </button>
                  </div>
                  <AccountContentBlock
                    headline="Title"
                    labelFor="title"
                    subheadline="Only visible for you"
                  >
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className="input input--markie input--account"
                      onChange={(e) => {
                        setNewMarkie((markie) => ({
                          ...(markie as Markie),
                          title: e.target.value,
                        }));
                      }}
                      value={newMarkie?.title}
                      placeholder="Your title"
                    ></input>
                  </AccountContentBlock>
                  <AccountContentBlock
                    headline="Video"
                    labelFor="link"
                    subheadline="Upload a video or start recording. We recommend videos of 5-10s."
                  >
                    <div className="flex column-nowrap input--account account-video-block">
                      {markieVideoUrl && (
                        <div className="flex align-items-flex-start uploaded-video">
                          <ActionButton
                            icon={<Trash fill="#fff" />}
                            version="error"
                            type="xs"
                            onClick={() => {
                              setVideoUploading(false);
                              deleteVideo();
                            }}
                            className="remove-uploaded-video"
                          />
                          <video
                            key={newMarkie.videoName}
                            src={markieVideoUrl}
                            controls
                          ></video>
                        </div>
                      )}
                      <label
                        htmlFor="upload-video"
                        className="web-button web-button--sm web-button--secondary web-button--stretch"
                      >
                        <div className="upload-video-text">
                          {isVideoUploading ? "Uploading..." : "Upload a video"}
                        </div>
                        <input
                          id="upload-video"
                          name="upload-video"
                          type="file"
                          className="upload-input"
                          accept="video/*"
                          onChange={(file) => {
                            uploadVideoFromDesktop(file);
                          }}
                        ></input>
                        <div
                          className="upload-video-loader"
                          style={{
                            transform: `scaleX(${uploadingAmount})`,
                          }}
                        ></div>
                      </label>
                      <div
                        className={`flex column-nowrap align-items-center divider`}
                      >
                        <p className="divider-text">or</p>
                        <div className="line"></div>
                      </div>
                      <ActionButton
                        version={"primary"}
                        type="sm"
                        label={"Record video"}
                        onClick={() => {
                          openMarkieRecorder();
                        }}
                        stretch={true}
                      />
                    </div>
                  </AccountContentBlock>
                  <AccountContentBlock
                    headline="Trigger"
                    labelFor="trigger"
                    subheadline="Configure how Markie should appear."
                  >
                    <div className="flex column-nowrap account-content-group-wrapper">
                      <div className="flex row-nowrap align-items-center builder-step-group">
                        <input
                          id="click"
                          type="radio"
                          name="appearance"
                          className="input-radio"
                          checked={newMarkie.trigger === "click"}
                          onChange={() => {
                            setNewMarkie((markie) => ({
                              ...(markie as Markie),
                              trigger: "click",
                            }));
                          }}
                        ></input>
                        <label
                          htmlFor="click"
                          className="flex row-nowrap align-items-center input-label input-label-radio"
                        >
                          Click
                          <div className="flex label-option-mark">
                            <QuestionMark
                              fill="#a1a1a1"
                              width={18}
                              height={18}
                              id="mrk-ui-trigger-click"
                            />
                          </div>
                        </label>
                      </div>
                      <div className="flex row-nowrap align-items-center builder-step-group">
                        <input
                          id="hover"
                          type="radio"
                          name="appearance"
                          className="input-radio"
                          defaultChecked={newMarkie.trigger === "hover"}
                          onChange={() => {
                            setNewMarkie((markie) => ({
                              ...(markie as Markie),
                              trigger: "hover",
                            }));
                          }}
                        ></input>
                        <label
                          htmlFor="hover"
                          className="flex row-nowrap align-items-center input-label input-label-radio"
                        >
                          Hover
                          <div className="flex label-option-mark">
                            <QuestionMark
                              fill="#a1a1a1"
                              width={18}
                              height={18}
                              id="mrk-ui-trigger-hover"
                            />
                          </div>
                        </label>
                      </div>
                      <div className="flex row-nowrap align-items-center builder-step-group">
                        <input
                          id="static"
                          type="radio"
                          name="appearance"
                          className="input-radio"
                          defaultChecked={newMarkie.trigger === "static"}
                          onChange={() => {
                            setNewMarkie((markie) => ({
                              ...(markie as Markie),
                              trigger: "static",
                              htmlElementSelector: "",
                            }));
                          }}
                        ></input>
                        <label
                          htmlFor="static"
                          className="flex row-nowrap align-items-center input-label input-label-radio"
                        >
                          Static
                        </label>
                      </div>
                      {newMarkie.trigger !== "static" ? (
                        <div className="flex column-nowrap builder-step-group">
                          <div className="markie-script-headline">
                            Html element
                          </div>
                          <div className="flex row-nowrap markie-input-group">
                            <Select
                              value={{
                                label:
                                  selectorDisplay[
                                    newMarkie.htmlElementSelector
                                  ],
                                value: newMarkie.htmlElementSelector,
                              }}
                              options={elementComp.map((comp) => ({
                                label: comp.display,
                                value: comp.value,
                              }))}
                              onChange={(
                                data: SingleValue<{
                                  label: string;
                                  value: "#" | "." | "";
                                }>
                              ) => {
                                if (!data) return;
                                setNewMarkie((markie) => ({
                                  ...(markie as Markie),
                                  htmlElementSelector: data.value,
                                }));
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                            <input
                              id="trigger"
                              type="text"
                              name="trigger"
                              className="input"
                              onChange={(e) => {
                                setNewMarkie((markie) => ({
                                  ...(markie as Markie),
                                  htmlElementID: e.target.value,
                                }));
                              }}
                              value={newMarkie.htmlElementID}
                              placeholder={`Your html element`}
                            ></input>
                          </div>
                          <div className="markie-script-description">{`Tip: we recommend using #id`}</div>
                        </div>
                      ) : (
                        <div className="flex column-nowrap builder-step-group">
                          <div className="markie-script-headline">Path</div>
                          <div className="flex row-nowrap markie-input-group">
                            <input
                              id="trigger"
                              type="text"
                              name="trigger"
                              className="input"
                              onChange={(e) => {
                                setNewMarkie((markie) => ({
                                  ...(markie as Markie),
                                  htmlElementID: e.target.value,
                                }));
                              }}
                              value={newMarkie.htmlElementID}
                              placeholder={`Your /page`}
                            ></input>
                          </div>
                          <div className="markie-script-description">{`Tip: Use the path after the domain (/ or /overview or /blog/10)`}</div>
                        </div>
                      )}
                    </div>
                  </AccountContentBlock>
                  <AccountContentBlock
                    headline="Play once"
                    labelFor="playOnce"
                    subheadline="Configure this Markie to be played once per user"
                  >
                    <div className="flex column-nowrap account-content-group-wrapper">
                      <div className="flex row-nowrap align-items-center builder-step-group">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          name="playOnce"
                          id="playOnce"
                          checked={
                            newMarkie.playOnce === undefined
                              ? false
                              : newMarkie.playOnce
                          }
                          onChange={(e) => {
                            setNewMarkie((markie) => ({
                              ...(markie as Markie),
                              playOnce:
                                markie?.playOnce !== undefined
                                  ? !markie.playOnce
                                  : true,
                            }));
                          }}
                        />
                        <div className="flex label-option-mark">
                          <QuestionMark
                            fill="#a1a1a1"
                            width={18}
                            height={18}
                            id="mrk-ui-play-once"
                          />
                        </div>
                      </div>
                    </div>
                  </AccountContentBlock>
                  <AccountContentBlock
                    headline="How to install"
                    subheadline="Copy & paste to get started."
                  >
                    <div className="flex column-nowrap account-content-group-wrapper">
                      <div className="flex column-nowrap builder-step-group">
                        {script && (
                          <Fragment>
                            <Accordion headline={`Install Markie`}>
                              <div className="flex column-nowrap markie-script-group">
                                <div className="markie-script-code">
                                  <pre>
                                    <code
                                      className="hljs language-javascript"
                                      dangerouslySetInnerHTML={{
                                        __html: script
                                          .replace(/</g, "&lt;")
                                          .replace(/>/g, "&gt;"),
                                      }}
                                    />
                                  </pre>
                                </div>
                                <ActionButton
                                  onClick={() => {
                                    navigator.clipboard
                                      .writeText(script)
                                      .then(() => {
                                        toast.success("Script copied");
                                      })
                                      .catch((err) => console.error(err));
                                  }}
                                  version="secondary"
                                  type="sm"
                                  label={"Copy to clipboard"}
                                  className="align-self-flex-start"
                                />
                                <div className="markie-script-description">{`Copy & paste this snippet before the </body>.`}</div>
                                <div className="markie-script-description">{`Tip: boot Markie with a anonymized random string.`}</div>
                              </div>
                            </Accordion>
                            <div className="markie-script-description">{`You only need to paste the snippets once per website.`}</div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </AccountContentBlock>
                </form>
              </Fragment>
            )}
          </Fragment>
        </AccountInfoGroup>
        <Fragment>
          {showMarkieRecorder && (
            <MarkieRecorder
              setMarkieVideo={setMarkieVideo}
              setShowMarkieRecorder={setShowMarkieRecorder}
              setVideoUploading={setVideoUploading}
              uploadVideo={uploadVideo}
              fetchVideoUrl={fetchVideoLink}
              closeRecorder={() => setShowMarkieRecorder(false)}
              uploadingAmount={uploadingAmount}
            />
          )}
          {showDeletePrompt && newMarkie && (
            <Prompt
              title={"Delete Markie"}
              text={`Are you sure you want to delete your Markie?`}
              onBackgroundClick={() => {
                setShowDeletePrompt(false);
              }}
            >
              <div className="flex row-nowrap justify-content-space-between prompt-actions">
                <div className="prompt-action prompt-action--half">
                  <ActionButton
                    onClick={() => {
                      setShowDeletePrompt(false);
                    }}
                    type="md"
                    version="secondary"
                    label="Cancel"
                    stretch
                  />
                </div>
                <div className="prompt-action prompt-action--half">
                  <ActionButton
                    onClick={() => {
                      deleteMarkie(newMarkie._id);
                      setShowDeletePrompt(false);
                    }}
                    type="md"
                    version="error"
                    label="Delete"
                    stretch
                  />
                </div>
              </div>
            </Prompt>
          )}
        </Fragment>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default AddMarkie;
