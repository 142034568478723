import { LightHouse, Wrench, Camera, Analytics } from "../../icon/icons";
import InternalButton from "../button/InternalButton";
import NavItem from "./NavItem";
import "./SidebarNavigation.css";

const SidebarNavigation = () => {
  return (
    <nav
      className={`flex column-nowrap justify-content-space-between sidebar-navigation sidebar-navigation--full`}
    >
      <div
        className={`flex column-nowrap sidebar-navigation-content sidebar-navigation-content--full`}
      >
        <div className="sidebar-navigation-subheadline">Navigation</div>
        <ul className="flex column-nowrap sidebar-navigation-list">
          <NavItem
            icon={<LightHouse />}
            label={"Overview"}
            link={"/overview"}
            className="mrk-ui-overview"
          />
          <div className="divider" />
          <NavItem
            icon={<Camera />}
            label={"Markies"}
            link={"/markies"}
            className="mrk-ui-markies"
          />
          <div className="divider" />
          <NavItem
            icon={<Wrench />}
            label={"Settings"}
            link={"/settings"}
            className="mrk-ui-settings"
          />
          <NavItem
            icon={<Analytics />}
            label={"Analytics"}
            link={"/analytics"}
            className="mrk-ui-analytics"
          />
        </ul>
      </div>
      <div
        className={`flex column-nowrap sidebar-navigation-content sidebar-navigation-content--full`}
      >
        <ul className="flex column-nowrap sidebar-navigation-list">
          <InternalButton
            version={"primary"}
            type="sm"
            label={"New Markie"}
            to={"/markie/new"}
            icon={<Camera fill="#fff" />}
            stretch={true}
            className={"mrk-ui-new-markie"}
          />
        </ul>
      </div>
    </nav>
  );
};

export default SidebarNavigation;
