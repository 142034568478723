import "./AccountInfoGroup.css";

const AccountInfoGroup = ({
  headline,
  description,
  children,
}: {
  headline: string;
  description: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <section className="flex column-nowrap account-info-group">
      <header className="flex row-nowrap align-items-center justify-content-space-between account-info-group-header">
        <div className="flex column-nowrap account-info-text-wrapper">
          <h1 className="account-info-group-headline">{headline}</h1>
          <p className="account-info-group-description">{description}</p>
        </div>
      </header>
      {children}
    </section>
  );
};

export default AccountInfoGroup;
