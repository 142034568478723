import ActionButton from "./button/ActionButton";
import InternalButton from "./button/InternalButton";
import "./Banner.css";

const Banner = ({
  icon,
  message,
  actionLabel,
  link,
  action,
  version,
  buttonVersion,
  stream = "horizontal",
}: {
  icon: JSX.Element;
  message: string;
  version: "primary" | "secondary";
  buttonVersion?: "primary" | "secondary";
  stream?: "horizontal" | "vertical";
  actionLabel?: string;
  link?: string;
  action?: () => void;
}) => {
  return (
    <div
      className={`flex ${
        stream === "horizontal" ? "row-nowrap align-items-center" : ""
      } ${
        stream === "vertical" ? "column-nowrap" : ""
      } justify-content-space-between banner banner--${version} banner--${stream}`}
    >
      <div
        className={`flex ${
          stream === "horizontal"
            ? "row-nowrap align-items-center banner-info-wrapper"
            : ""
        } ${stream === "vertical" ? "column-nowrap banner-info-wrapper" : ""}`}
      >
        <div className="flex banner-icon">{icon}</div>
        <div className="banner-message">{message}</div>
      </div>
      {link && typeof link === "string" && (
        <InternalButton
          version={buttonVersion || "primary"}
          type="sm"
          label={actionLabel}
          to={link}
          stretch={stream === "vertical" ? true : false}
        />
      )}
      {action && typeof action === "function" && (
        <ActionButton
          version={buttonVersion || "primary"}
          type="sm"
          label={actionLabel}
          onClick={() => {
            action();
          }}
          stretch={stream === "vertical" ? true : false}
        />
      )}
    </div>
  );
};

export default Banner;
