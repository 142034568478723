import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { apiUrl } from "../shared/utils";

export const Invitations = () => {
  const [numOfInvites, setNumOfInvites] = useState<number>(1);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post(
        apiUrl("account/invitations/create"),
        {
          numberOfInvitations: numOfInvites,
          usagePerInvitation: 1,
        },
        { withCredentials: true }
      )
      .then((_) => toast.success("Created"));
  };

  const handleDownloadCodes = () => {
    axios
      .get(apiUrl("account/invitations/csv"), {
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "codes.csv");
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div>
      <div>Create invitations</div>
      <form onSubmit={handleFormSubmit}>
        <label>Number:</label>
        <input
          value={numOfInvites}
          type="number"
          onChange={(e) => setNumOfInvites(parseInt(e.target.value))}
        ></input>
        <button type="submit">Create</button>
      </form>
      <hr></hr>
      <button onClick={handleDownloadCodes}>Download codes</button>
    </div>
  );
};
