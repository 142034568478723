import { Fragment } from "react";
import "./Chip.css";

const Chip = ({
  text,
  version,
}: {
  text: Record<string, any>;
  version?: string;
}) => {
  const renderChip = () => {
    if (text && text.length !== 0) {
      return text.map((t: Record<string, any>, index: number) => {
        if (text.length <= 1 || text.length - 1 === index) {
          return (
            <p className={`chip chip--${version}`} key={t.id}>
              {t.name}
            </p>
          );
        } else {
          return (
            <Fragment key={t.id}>
              <p className={`chip chip--${version}`}>{t.name}</p>
              <span className={`chip-next-text`}>then</span>
            </Fragment>
          );
        }
      });
    } else {
      return "";
    }
  };
  return (
    <div className={`flex row-nowrap align-items-center chip-wrapper`}>
      {renderChip()}
    </div>
  );
};

export default Chip;
