import { useState } from "react";
import "./MarkieVisualizer.css";

const MarkieVisualizer = ({
  primaryColour,
  secondaryColour,
  appearance,
}: {
  primaryColour: string;
  secondaryColour: string;
  appearance: string;
}) => {
  const [toggleSound, setToggleSound] = useState<boolean>(false);

  const handleToggleSound = () => {
    setToggleSound((sound) => !sound);
  };

  return (
    <div className={`flex align-items-center markie-visualizer`}>
      <div className={`flex markie-visualizer-wrapper`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 29 29"
          width="29"
          height="29"
          className={`markie-visualizer-arrow-svg markie-visualizer-arrow-svg--${appearance}`}
        >
          <path
            fill={primaryColour}
            d="M0.4,3.8l11.7,22.9c0.4,0.8,1.4,1.1,2.2,0.7c0.4-0.2,0.6-0.5,0.8-0.9l3.3-8.4
	c0.1-0.4,0.4-0.7,0.8-0.9l8.7-4.6c0.8-0.4,1.1-1.4,0.7-2.2c-0.2-0.4-0.5-0.7-0.9-0.8L2.4,1.5c-0.9-0.3-1.8,0.2-2.1,1
	C0.2,2.9,0.2,3.4,0.4,3.8z"
          />
        </svg>
        <div
          className={`flex markie-visualizer-video-wrapper markie-visualizer-video-wrapper--${appearance}`}
          style={{
            border: `7px solid ${
              toggleSound ? secondaryColour : primaryColour
            }`,
          }}
          onClick={handleToggleSound}
        >
          {toggleSound ? (
            <svg
              version="1.1"
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 36 36"
              width={36}
              height={36}
              fill={secondaryColour}
              className="sound-icon-wrapper"
            >
              <path
                id="speaker"
                d="M16.4,31c-0.4,0-0.7-0.1-1-0.4l-7.6-6.1H2c-0.9,0-1.6-0.7-1.6-1.6v-9.8c0-0.9,0.7-1.6,1.6-1.6h5.8l7.6-6.1
	c0.5-0.4,1.1-0.5,1.7-0.2C17.6,5.4,18,6,18,6.6v22.8c0,0.6-0.4,1.2-0.9,1.4C16.9,30.9,16.6,31,16.4,31z M3.6,21.3h4.8
	c0.4,0,0.7,0.1,1,0.4l5.4,4.4V10l-5.4,4.4c-0.3,0.2-0.6,0.4-1,0.4H3.6V21.3z"
              />
              <path
                id="sound"
                d="M29.3,31.1c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.3c5.6-5.7,5.6-15,0-20.8c-0.6-0.6-0.6-1.6,0-2.3
	c0.6-0.6,1.6-0.6,2.3,0c6.8,7,6.8,18.3,0,25.2C30.1,30.9,29.7,31.1,29.3,31.1z M23.7,25.3c-0.4,0-0.8-0.2-1.1-0.5
	c-0.6-0.6-0.6-1.6,0-2.3c2.5-2.6,2.5-6.7,0-9.3c-0.6-0.6-0.6-1.6,0-2.3s1.6-0.6,2.3,0c3.7,3.8,3.7,10,0,13.7
	C24.5,25.2,24.1,25.3,23.7,25.3z"
              />
            </svg>
          ) : (
            <svg
              version="1.1"
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 36 36"
              width={36}
              height={36}
              fill={secondaryColour}
              className="sound-icon-wrapper"
            >
              <path
                id="speaker"
                d="M15.7,30.1c-0.4,0-0.7-0.1-1-0.3l-7.2-5.7H2c-0.9,0-1.6-0.7-1.6-1.6v-9c0-0.9,0.7-1.6,1.6-1.6h5.5l7.2-5.7
	c0.5-0.4,1.1-0.5,1.7-0.2c0.6,0.3,0.9,0.8,0.9,1.4v21c0,0.6-0.4,1.2-0.9,1.4C16.2,30,16,30.1,15.7,30.1z M3.6,20.9h4.5
	c0.4,0,0.7,0.1,1,0.3l5,4V10.8l-5,4c-0.3,0.2-0.6,0.3-1,0.3H3.6V20.9z"
              />
              <path
                id="muted"
                d="M31.7,18l3.4-3.4c0.6-0.6,0.6-1.6,0-2.3c-0.6-0.6-1.6-0.6-2.3,0l-3.4,3.4L26,12.4c-0.6-0.6-1.6-0.6-2.3,0
	c-0.6,0.6-0.6,1.6,0,2.3l3.4,3.4l-3.4,3.4c-0.6,0.6-0.6,1.6,0,2.3c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5l3.4-3.4l3.4,3.4
	c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5c0.6-0.6,0.6-1.6,0-2.3L31.7,18z"
              />
            </svg>
          )}
          <video
            src={`/assets/markie/markie-explaining-0.mp4`}
            muted
            autoPlay
            playsInline
            loop
            className="markie-visualizer-video"
            poster={`/assets/markie/markie-poster-0.png`}
          ></video>
        </div>
      </div>
    </div>
  );
};

export default MarkieVisualizer;
